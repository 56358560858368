import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonModal,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  getDeviceGraphs,
  searchDevices,
  sendCalibrationData,
  updateDeviceDetails,
} from "../services/device.service";
import moment from "moment";
import TimeSeriesChart from "./TimeSeriesChart";
import { getChartData } from "./PlotSensorData";
import { closeCircleOutline } from "ionicons/icons";
import { createFieldExtractor } from "../utilities/utils";
import _ from "lodash";
import { getSoilTypes } from "../services/plot.service";
import SelectItem from "./SelectItem";

type CalibrationData = {
  [key: string]: { [field: string]: string | number };
};

const DeviceGraphs: React.FC = () => {
  const location = useLocation();
  const [deviceData, setDeviceData] = useState<any>();
  const [deviceGraphs, setDeviceGraphs] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sinceHours, setSinceHours] = useState<number>(240);
  const [ccid, setCcid] = useState<string>('');
  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");
  const [soilTypes, setSoilTypes] = useState<any[]>([])
  const [showSelectSoilTypeModal, setShowSelectSoilTypeModal] = useState(false);
  const [selectedMoistureForModal, setSelectedMoistureForModal] = useState('');


  const [device, setDevice] = useState({ id: "", imei: "" });

  useEffect(() => {
    if (location.state) {
      const locationState = location.state as any;
      const deviceId = locationState.deviceId ?? "";
      const imei = locationState.imei ?? "";
      fetchSoilTypes();
      if (deviceId) {
        getGraphs(deviceId);
        getDeviceData(deviceId);
        setDevice({
          id: deviceId,
          imei,
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (!isLoading) {
      getGraphs(device.id);
    }
  }, [sinceHours]);

  const getDeviceData = async (deviceId: string) => {
    const devices = await searchDevices(deviceId, 0, 10);
    setDeviceData(devices[0]);
    if (devices[0]?.calibration) {
      setDefaultCalibrationData(_.merge({}, defaultCalibrationData, { calibration: devices[0]?.calibration }));
    }
    if (devices[0]?.ccid) {
      setCcid(devices[0]?.ccid);
    }
  };

  const getGraphs = async (deviceId: string) => {
    setIsLoading(true);
    const timestamp = moment().subtract(sinceHours, "hours").toDate();
    const graphs = await getDeviceGraphs(deviceId, timestamp);
    setDeviceGraphs(graphs);
    setIsLoading(false);
  };


  const extractNumericFields = createFieldExtractor("number");
  const fields = extractNumericFields(deviceGraphs ?? []);


  const [defaultCalibrationData, setDefaultCalibrationData] = useState<{
    calibration: CalibrationData;
  }>({
    calibration: {
      moisture1: { air: '', water: '', soil: '', soilType: '' },
      moisture2: { air: '', water: '', soil: '', soilType: '' },
      moisture3: { air: '', water: '', soil: '', soilType: '' },
      moisture4: { air: '', water: '', soil: '', soilType: '' },
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableData, setEditableData] = useState<CalibrationData>({});

  const handleOpenModal = () => {
    setEditableData({ ...defaultCalibrationData.calibration });
    setIsModalOpen(true);
  };

  const handleFieldChange = (moisture: string, key: string, value: string) => {
    setEditableData((prev) => ({
      ...prev,
      [moisture]: {
        ...prev[moisture],
        [key]: value,
      },
    }));
  };

  const handleSave = async () => {
    const deviceId = device.id;
    try {
      const response = await sendCalibrationData(deviceId, editableData);

      if (response.success) {
        setDefaultCalibrationData((prev) => ({
          ...prev,
          calibration: editableData,
        }));
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error saving calibration data", error);
    }
  };

  const updateDevice = async () => {
    const deviceId = device.id;
    try {
      const response = await updateDeviceDetails(deviceId, { ccid });
      if (response.success) {
        setOnSuccess(response.message!);
      } else {
        setOnError(response.message!);
      }
    } catch (error) {
      setOnError('Some error occured');
    }
  };

  const handleClearField = (moisture: string, key: string) => {
    setEditableData((prevData) => ({
      ...prevData,
      [moisture]: {
        ...prevData[moisture],
        [key]: NaN,
      },
    }));
  };

  const fetchSoilTypes = async () => {
    try {
      const data = await getSoilTypes();
      setSoilTypes(data as []);
    } catch (err) {
      console.error("Error in fetching soil", err);
    }
  };

  const findSoilNameUsingId = (soilId: any) => {
    const reqSoilObj = soilTypes.filter(
      (soil) => soil.id === soilId
    );
    return reqSoilObj[0]?.name;
  };

  function setSoilType(item: any) {
    editableData[selectedMoistureForModal]['soilType'] = item.id;
    setShowSelectSoilTypeModal(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Device Graphs</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItem>
          <IonLabel position="stacked">IMEI</IonLabel>
          <IonInput
            readonly
            id="farmId"
            name="farmId"
            value={device.imei}
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">CCID</IonLabel>
          <IonInput
            id="ccid"
            name="ccid"
            value={ccid}
            onIonChange={(e) => setCcid(e.detail.value ?? '')}
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Plot ID</IonLabel>
          <IonInput
            id="plotId"
            name="plotId"
            readonly
            value={deviceData?.plotId}
            placeholder="Plot ID"
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Batch ID</IonLabel>
          <IonInput
            id="batchId"
            name="batchId"
            readonly
            value={deviceData?.batchId}
            placeholder="Batch ID"
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Since Hours (1-720 hrs)</IonLabel>
          <IonInput
            id="device-graphs-sinceHours"
            name="sinceHours"
            type="number"
            inputmode="numeric"
            value={sinceHours}
            placeholder="Default is 24 hrs"
            debounce={1000}
            onIonChange={(e: any) => setSinceHours(e.target.value ?? 24)}
          ></IonInput>
        </IonItem>

        <IonButton expand="block" color="primary" onClick={updateDevice}>
          Update CCID
        </IonButton>
        <IonButton expand="block" color="primary" onClick={handleOpenModal}>
          Calibration List
        </IonButton>

        {isLoading ? (
          <div
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              paddingTop: "20px",
            }}
          >
            <IonSpinner />
          </div>
        ) : (
          <IonList>
            {fields.length > 0 ? (
              fields.map((field: any, index: any) => (
                <IonCard key={`device-graph-${index}-${field}`}>
                  <IonCardHeader>
                    <IonCardSubtitle>{field} </IonCardSubtitle>
                  </IonCardHeader>
                  <TimeSeriesChart
                    format={"date"}
                    chartData={getChartData(deviceGraphs ?? [], field)}
                  />
                </IonCard>
              ))
            ) : (
              <div
                style={{
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  paddingTop: "20px",
                }}
              >
                <IonLabel>No Graph Found</IonLabel>
              </div>
            )}
          </IonList>
        )}

        <IonModal
          isOpen={isModalOpen}
          onDidDismiss={() => setIsModalOpen(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Calibration List</IonTitle>
              <IonButton
                slot="end"
                fill="clear"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ marginBottom: "100px" }}>
              {Object.entries(editableData).map(
                ([moisture, Calibrationfields]) => (
                  <IonItem key={moisture} lines="none">
                    <IonLabel position="stacked">
                      <IonCardSubtitle>{moisture}</IonCardSubtitle>
                    </IonLabel>
                    <IonGrid >
                      {Object.entries(Calibrationfields).map(([key, value]) => (
                        <IonRow
                          key={key}
                          className="ion-align-items-center ion-margin-vertical"
                          style={{ width: "100%" }}
                        >
                          <IonCol size="4">
                            <IonLabel>{key}</IonLabel>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem
                              lines="full"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "15px",
                                padding: "0 10px",
                              }}
                            >
                              {key !== 'soilType'
                                ?
                                <IonInput
                                  type="number"
                                  value={value ?? ""}
                                  onIonChange={(e) =>
                                    handleFieldChange(
                                      moisture,
                                      key,
                                      e.detail.value as string
                                    )
                                  }
                                  style={{ width: "100%" }}
                                />
                                :
                                <IonInput
                                  readonly
                                  id="soilTypeId"
                                  name="soilTypeId"
                                  value={value ? findSoilNameUsingId(value) : ''}
                                  placeholder="Select Soil Type"
                                  onClick={(e) => { setSelectedMoistureForModal(moisture); setShowSelectSoilTypeModal(true) }}
                                ></IonInput>
                              }

                            </IonItem>
                          </IonCol>
                          <IonCol size="2">
                            <IonButton
                              fill="clear"
                              color="danger"
                              onClick={() => handleClearField(moisture, key)}
                              style={{ padding: "0", height: "100%" }}
                            >
                              <IonIcon icon={closeCircleOutline} />
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      ))}
                    </IonGrid>
                  </IonItem>
                )
              )}
            </IonList>

            <IonButton
              className="floating-button"
              expand="block"
              color="primary"
              onClick={handleSave}
            >
              Save Changes
            </IonButton>
          </IonContent>
        </IonModal>


        {showSelectSoilTypeModal && (
          <IonModal id="soil-modal" isOpen={showSelectSoilTypeModal}>
            <SelectItem
              label="Select Soil Type"
              items={soilTypes}
              hideSearchbar={true}
              selectedValue={''}
              labelKey="name"
              valueKey="soilTypeId"
              displayType="card"

              setItem={setSoilType}
              onClose={setShowSelectSoilTypeModal}
            >
            </SelectItem>
          </IonModal>
        )}

        <IonToast
          isOpen={!!onSuccess}
          onDidDismiss={() => setOnSuccess("")}
          message={onSuccess}
          duration={2000}
          color="success"
        />

        <IonToast
          isOpen={!!onError}
          onDidDismiss={() => setOnError("")}
          message={onError}
          duration={2000}
          color="danger"
        />

      </IonContent>
    </IonPage>
  );
};

export default DeviceGraphs;
